<template>
	<SystemDialogModal
		:title="$t('builder.userStyles.colors.modal.title')"
		:primary-button-text="$t('common.reset')"
		:secondary-button-text="$t('common.keepChanges')"
		@close="closeModal"
		@click-primary="handleReset"
		@click-secondary="closeModal"
	>
		<p>
			{{ $t('builder.userStyles.modal.subtitle') }}
		</p>
	</SystemDialogModal>
</template>

<script>
import { mapState } from 'vuex';

import { useColorSetsLibrary } from '@/components/builder-drawers/drawers/partials/stylesDrawer/colors/use/useColorSetsLibrary';
import SystemDialogModal from '@/components/builder-modals/modals/SystemDialogModal.vue';
import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';

export default {
	components: { SystemDialogModal },
	setup() {
		const {
			setColorSet,
			resetSelectedPaletteToDefault,
		} = useColorSetsLibrary();

		return {
			setColorSet,
			resetSelectedPaletteToDefault,
		};
	},
	computed: { ...mapState('gui', ['activeModalSettings']) },
	methods: {
		...mapActionsGui({ closeModal: CLOSE_MODAL }),
		handleReset() {
			const {
				colorSet,
				colorSetId,
			} = this.activeModalSettings;

			this.resetSelectedPaletteToDefault();
			this.setColorSet(colorSet, colorSetId);
			this.closeModal();
		},
	},
};
</script>
